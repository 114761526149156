import React from 'react'
import * as styles from './hero-image.module.scss';

import { Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"

const HeroImage = ({ data, position = false }) => {
    const {
        heroImage,
        subTitle,
        title,
        subTitleLong
    } = data
    return (
        <section className={styles.heroContainer}>
            <div className={styles.imageContainer}>
                <div className={styles.container}>
                    <GatsbyImage image={heroImage.gatsbyImageData} className={styles.image} loading={position && "eager"} alt={`Red Rose ${title}`} loading="eager" />
                    <div className={`${styles.imageContent}`}>
                        <div className='hero-content'>
                            <h2 className={styles.heroTitle}>{title}</h2>
                            <p className={styles.heroSubtitle}>{subTitle}</p>
                            <p className={styles.heroSubtitle}>{subTitleLong?.subTitleLong}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HeroImage