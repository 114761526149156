import React from 'react'
import * as styles from './article-card.module.scss'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { useLocalePath } from '../../../hooks'
const ArticleCard = ({ thumbnailImage, title, slug }) => {
    return (
        <div>
            <Link to={useLocalePath(slug)}>
                <figure>
                    {/* <Link to={useLocalePath(slug)}> */}
                    <GatsbyImage className={styles.articleImage} image={thumbnailImage.gatsbyImageData} alt={`Red Rose ${title}`} />
                </figure>
                <div>
                    <h3 className={styles.articleTitle}>{title}</h3>
                    {/* </Link> */}
                </div>
            </Link >
        </div >
    )
}

export default ArticleCard