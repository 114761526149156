import React from 'react'
import ArticleCard from '../ArticleCard/ArticleCard';
import * as styles from './grid-articles.module.scss'

const GridArticles = ({ data }) => {
    const {
        title,
        articles
    } = data
    return (
        <section>
            <div className={styles.articleContainer}>
                <h2>{title}</h2>
                <div className={styles.articleGrid}>
                    {
                        articles.map(({ thumbnailTitle, thumbnailImage, slug }, index) => {
                            return (
                                <div key={index}>
                                    <ArticleCard thumbnailImage={thumbnailImage} title={thumbnailTitle} slug={slug} />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default GridArticles