import React from 'react'
import * as styles from './grid-products.module.scss'

import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { useLocalePath } from '../../../hooks';
import ProductCard from '../ProductCard/ProductCard';
const GridProducts = ({ data }) => {
    const {
        products,
        title
    } = data

    return (
        <section className={styles.categoryProductContainer}>
            <div>
                {title && <h2 className={styles.categoryTitle}>{title}</h2>}
                <div className={styles.productGrid}>
                    {
                        products.map(({ heroImage, slug, title }, index) => {
                            return (
                                <article key={index} className={styles.productCard}>
                                    <ProductCard heroImage={heroImage} slug={slug} title={title} />
                                </article>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default GridProducts