import React from 'react'
import * as styles from './product-card.module.scss'
import { Link } from 'gatsby'
import { useLocalePath, useSiteQuery } from '../../../hooks'
import { GatsbyImage } from 'gatsby-plugin-image'
const ProductCard = ({ heroImage, title, slug }) => {
    const { buyNowButton } = useSiteQuery()
    return (
        <div className={styles.productCard}>
            <Link to={useLocalePath(slug)}>
                <div className={styles.imageThumbnail}>
                    <GatsbyImage className={styles.image} image={heroImage?.gatsbyImageData} alt={`Red Rose - ${title}`} />
                </div>
                <div className={styles.content}>
                    <span className={styles.productContent}>{title}</span>
                    {/* <Link to={useLocalePath(slug)}><span className={styles.productContent}>{title}</span></Link> */}
                    {/* <Link to={useLocalePath(slug)}><span className={styles.productContent}>{buyNowButton}</span></Link> */}
                </div>
            </Link>
        </div>
    )
}

export default ProductCard