import { graphql } from 'gatsby'
import React from 'react'
import GridArticles from '../components/Shared/GridArticles/GridArticles'
import GridProducts from '../components/Shared/GridProducts/GridProducts'
import HeroImage from '../components/Shared/HeroImage/HeroImage'
import Seo from '../components/Shared/Seo/Seo'
import { useLocale, useSchema } from '../hooks'
import * as styles from '../styles/local.module.scss'
const ProductsCategory = ({
    location,
    pageContext,
    data: {
        contentfulPageProductsCategory: {
            seoTitle,
            seoDescription,
            slug,
            sections,
            breadCrumb,
            itemList
        },
        allContentfulPageProductsCategory: {
            nodes: allLinks
        }
    }
}) => {
    const locale = useLocale()

    return (
        <div>
            <h1 style={{ display: 'none' }}>{seoTitle}</h1>

            <Seo
                slug={slug}
                title={seoTitle}
                description={seoDescription.seoDescription}
                lang={locale}
                hrefLangs={allLinks}
                schemas={[
                    useSchema(
                        "breadcrumbs",
                        {
                            breadCrumbs: breadCrumb
                        }
                    ),
                    itemList && useSchema(
                        'itemList',
                        {
                            slug: slug,
                            seoTitle: seoTitle,
                            items: itemList
                        }
                    )
                ]}
            />
            <div className={styles.container}>
                {
                    sections.map((section, index) => {
                        switch (section.__typename) {
                            case "ContentfulComponentHero":
                                return (
                                    <HeroImage data={section} key={index} position={index == 0} />
                                )
                            case "ContentfulComponentGridSection":
                                return section.type === 'Products'
                                    ? (
                                        <GridProducts data={section} key={index} />
                                    ) : section.type === 'Articles'
                                        ? (
                                            <GridArticles data={section} key={index} />
                                        )
                                        : null
                        }
                    })
                }
            </div>
        </div>
    )
}

export const ProductsCategoryQuery = graphql`
    query PageProductsCategory($id: String!, $contentful_id: String!){
        contentfulPageProductsCategory(id: {eq: $id}) {
            slug
            seoTitle
            seoDescription {
              seoDescription
            }
            breadCrumb {
                title
                slug
            }
            itemList {
                ... on ContentfulPageProduct {
                    slug
                }
                ... on ContentfulPageProductsCategory {
                    slug
                }
            }
            sections {
                ... on ContentfulComponentHero {
                    __typename
                    title
                    subTitle
                    heroImage {
                        title
                        gatsbyImageData(quality: 80)
                    }
                    subTitleLong {
                        subTitleLong
                    }
                }
                ... on ContentfulComponentGridSection {
                    __typename
                    title
                    type
                    products {
                        title
                        slug
                        heroImage {
                            title
                            gatsbyImageData(quality: 75)
                        }
                    }
                    articles {
                        thumbnailTitle
                        thumbnailImage {
                            title
                            gatsbyImageData(quality: 75)
                        }
                        slug
                    }
                }
            }
        }
        allContentfulPageProductsCategory(filter: {contentful_id: {eq: $contentful_id}, id: {ne: $id}}){
            nodes {
                slug
                node_locale
            }
        }   
    }
`

export default ProductsCategory